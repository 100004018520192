


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    menuItem: () => import('@/components/base/InsMenuItem.vue')
  }
})
export default class InsElMenu extends Vue {
  @Prop({ default: 'vertical' }) private mode!: string; // 菜单模式（vertical：纵向；horizontal：横向）
  @Prop({ default: '#ffffff' }) private backColor!: string; // 菜单的背景色
  @Prop({ default: '#303133' }) private textColor!: string; // 菜单的文字颜色
  @Prop({ default: '#409EFF' }) private activeTextColor!: string; // 当前激活菜单的文字颜色
  @Prop({ default: false }) private defaultActive!: boolean; // 当前激活菜单的 index
  @Prop({ default: false }) private uniqueOpened!: boolean; // 是否只保持一个子菜单的展开
  @Prop({ default: 'header' }) private type!: string; // 菜单数据类型（头部/脚部）
  show:boolean = false;
  searchKey: string = '';
  subMenuData:object[] = [];

  handleOpen (key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose (key, keyPath) {
    console.log(key, keyPath);
  }

  get MenuList () {
    if (this.type === 'header') {
      return this.$store.state.headerMenus;
    } else if (this.type === 'footer') {
      return this.$store.state.footerMenus;
    }
  }
  showSubMenu (item) {
    this.show = true;
    this.subMenuData = item.Childs;
  }

  closeSlideMenu () {
    this.show = false;
    this.$store.dispatch('isShowMenu', false);
  }

  toUrl (url) {
    if (url) {
      window.location.href = url;
    }
  }

  To (n) {
    if (n.Url !== '') {
      return n.Url;
    } else {
      return n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/CMS/content/' + n.Value.Id : n.Type === 3 ? '/RegNPay/Form/' + n.Value.Id : (n.Type === 4 && n.Value.Id) ? '/product/cat/' + n.Value.Id : n.Type === 5 ? '/product/search/+?attrs=' + JSON.stringify([{ Id: parseInt(n.Value.Id), Vals: [] }]) + '&type=0' : '';
    }
  }

  mounted () {
    // console.log(this.MenuList, '菜单');
  }

  @Watch('showMenu', { deep: true })
  onShowMenu (val) {
    if (val) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }
}
