import { render, staticRenderFns } from "./InsElMenu.vue?vue&type=template&id=4fd52347&scoped=true&"
import script from "./InsElMenu.vue?vue&type=script&lang=ts&"
export * from "./InsElMenu.vue?vue&type=script&lang=ts&"
import style0 from "./InsElMenu.vue?vue&type=style&index=0&id=4fd52347&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd52347",
  null
  
)

export default component.exports